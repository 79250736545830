import React, { useState, useEffect } from 'react'; // Импорт React и необходимых хуков
import { useLocation, useNavigate } from 'react-router-dom'; // Импорт хуков для работы с маршрутами
import { ReactComponent as BackArrow } from '../assets/back-arrow.svg'; // Импорт компонента с SVG-стрелкой
import Slider from 'react-slick'; // Импорт библиотеки слайдера
import 'slick-carousel/slick/slick.css'; // Импорт стилей для слайдера
import 'slick-carousel/slick/slick-theme.css'; // Импорт темы стилей для слайдера
import './OrganizationCardScreen.css'; // Импорт стилей для данного компонента
import SimpleModal from './SimpleModal'; // Импорт модального компонента

const OrganizationCardScreen = () => { // Компонент экрана карточки организации
  const location = useLocation(); // Получение информации о текущем маршруте
  const navigate = useNavigate(); // Функция для навигации по маршрутам
  const organization = location.state?.organization; // Получение информации об организации из состояния маршрута
  const [showFullDescription, setShowFullDescription] = useState(false); // Состояние для отображения полного описания
  const [modalOpen, setModalOpen] = useState(false); // Состояние для открытия и закрытия модального окна с изображениями
  const [selectedImageIndex, setSelectedImageIndex] = useState(0); // Состояние для выбора текущего изображения
  const [isAnimating, setIsAnimating] = useState(true); // Состояние для контроля анимации

  const handleBack = () => { // Обработчик нажатия кнопки "Назад"
    setIsAnimating(false); // Остановка анимации
    setTimeout(() => {
      navigate(-1); // Вернуться назад после завершения анимации
    }, 500); // Задержка, равная длительности анимации
  };

  useEffect(() => { // Эффект для запуска анимации при монтировании и очистки при размонтировании
    setIsAnimating(true);
    return () => setIsAnimating(false);
  }, []);

  if (!organization) { // Если информация об организации еще не загружена
    return <div className="loading">Загрузка...</div>; // Отобразить сообщение о загрузке
  }

  const allImages = [organization.logo, ...organization.photos]; // Создание массива всех изображений

  const handleImageClick = (index) => { // Обработчик клика по изображению
    setSelectedImageIndex(index); // Установка выбранного индекса изображения
    setModalOpen(true); // Открытие модального окна с изображениями
  };

  const settings = { // Настройки слайдера
    dots: true, // Отображение точек навигации
    infinite: true, // Бесконечная прокрутка
    speed: 500, // Скорость прокрутки
    slidesToShow: 1, // Количество видимых слайдов
    slidesToScroll: 1, // Количество прокручиваемых слайдов
  };

  const handleButtonAction = (button) => { // Обработчик действия по кнопке
    if (button.type === 'url') { // Если тип кнопки - URL
      window.open(button.action, '_blank'); // Открыть ссылку в новой вкладке
    }
  };

  return (
    <div className={`page-background ${isAnimating ? 'fade-in' : 'fade-out'}`}> {/* Компонент с фоном страницы и анимацией */}
      <BackArrow onClick={handleBack} className="back-button" /> {/* Компонент кнопки "Назад" */}
      <div className="organization-card-container"> {/* Контейнер карточки организации */}
        <h1>{organization.companyName}</h1> {/* Заголовок с названием организации */}
        <div className="image-gallery"> {/* Контейнер для изображений с использованием слайдера */}
          <Slider {...settings}> {/* Инициализация слайдера с настройками */}
            {allImages.map((image, index) => ( // Маппинг изображений
              <div key={index} onClick={() => handleImageClick(index)}> {/* Контейнер для изображения с обработчиком клика */}
                <img src={image} alt={`Фото ${index}`} className="main-image" /> {/* Изображение */}
              </div>
            ))}
          </Slider>
        </div>
        <p className="address">{organization.address}</p> {/* Адрес организации */}
        <div className="description"> {/* Описание организации */}
          {showFullDescription || !organization.description || organization.description.length <= 200 ? (
            organization.description
          ) : (
            <>
              {organization.description.slice(0, 200)}...
              <button onClick={() => setShowFullDescription(true)}>показать полностью</button> {/* Кнопка для отображения полного описания */}
            </>
          )}
        </div>
        <div className="buttons-container"> {/* Контейнер для кнопок */}
          {organization.buttons.map((button, index) => ( // Маппинг кнопок организации
            <button
              key={index}
              className={`action-button ${button.color}`}
              onClick={() => handleButtonAction(button)}
            >
              {button.title}
            </button>
          ))}
        </div>
      </div>
      <SimpleModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        images={allImages}
        selectedImageIndex={selectedImageIndex}
      /> {/* Модальное окно с изображениями */}
    </div>
  );
};

export default OrganizationCardScreen; // Экспорт компонента
