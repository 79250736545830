import axios from 'axios';

// Определяем базовый URL для всех запросов к API.
const BASE_URL = 'https://touristgiftcertificates.uz/shop-api/api/';

// Функция для выполнения GET-запросов.
const makeGetRequest = async (url) => {
  try {
    // Выполнение GET-запроса с использованием axios.
    // 'url' - полный адрес запроса.
    // В заголовках указываем, что ожидаем JSON-ответ.
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    // Возвращаем данные ответа.
    return response.data;
  } catch (error) {
    // Обработка и логирование ошибок при запросе.
    console.error('Ошибка при выполнении GET-запроса:', error);
    // Перебрасываем ошибку дальше, чтобы её можно было обработать на более высоком уровне.
    throw error;
  }
};

// Экспорт функций для получения различных данных через API.
// Каждая функция использует 'makeGetRequest' для выполнения запроса и обработки ответа.

// Получение списка стран.
export const getCountries = () =>
  makeGetRequest(`${BASE_URL}country`)
    .then(data => data.data.countries);

// Получение списка городов в зависимости от выбранной страны.
// 'countryId' - идентификатор страны.
export const getCities = (countryId) =>
  makeGetRequest(`${BASE_URL}city?country=${countryId}`)
    .then(data => data.data.cities);

// Получение списка категорий в зависимости от выбранной страны и города.
// 'countryId' и 'cityId' - идентификаторы страны и города соответственно.
export const getCategories = (countryId, cityId) =>
  makeGetRequest(`${BASE_URL}business?country=${countryId}&city=${cityId}`)
    .then(data => data.data.businesses);

// Получение списка организаций на основе выбранной страны, города и категории.
// 'countryId', 'cityId', и 'categoryId' - идентификаторы страны, города и категории соответственно.
export const getOrganizations = (countryId, cityId, categoryId) =>
  makeGetRequest(`${BASE_URL}companies?country=${countryId}&city=${cityId}&business=${categoryId}`)
    .then(data => data.data.companies);

// Получение информации об организации по её идентификатору.
// 'organizationId' - идентификатор организации.
export const getOrganization = (organizationId) =>
  makeGetRequest(`${BASE_URL}organization/${organizationId}`);
