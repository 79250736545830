import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// Импорт компонентов экранов приложения.
import LocationAndCategorySelectScreen from './screens/LocationAndCategorySelectScreen';
import OrganizationListScreen from './screens/OrganizationListScreen';
import OrganizationCardScreen from './screens/OrganizationCardScreen';

const App = () => {
  return (
    // Использование Router для оборачивания всего приложения в контекст маршрутизации.
    <Router>
      {/* Routes компонент, определяющий, какой компонент должен быть отрендерен на основе URL */}
      <Routes>
        {/* Определение маршрутов и связанных с ними компонентов */}
        {/* Когда URL соответствует "/", отображается LocationAndCategorySelectScreen */}
        <Route path="/" element={<LocationAndCategorySelectScreen />} />

        {/* Когда URL соответствует "/organizations/:countryId/:cityId/:categoryId", 
        отображается OrganizationListScreen. :countryId, :cityId, :categoryId - это параметры URL. */}
        <Route path="/organizations/:countryId/:cityId/:categoryId" element={<OrganizationListScreen />} />

        {/* Когда URL соответствует "/organization/:id", 
        отображается OrganizationCardScreen. :id - это параметр URL. */}
        <Route path="/organization/:id" element={<OrganizationCardScreen />} />
      </Routes>
    </Router>
  );
};

export default App;
