import React, { useState, useEffect } from 'react'; // Импорт React и необходимых хуков
import { useParams, useNavigate, useLocation } from 'react-router-dom'; // Импорт хуков для работы с маршрутами и местоположением
import { getOrganizations } from '../services/ApiService'; // Импорт функции для получения данных об организациях
import { ReactComponent as BackArrow } from '../assets/back-arrow.svg'; // Импорт компонента с векторной иконкой
// Предположим, что ваш файл CSS импортируется здесь
import './OrganizationListScreen.css'; // Импорт стилей для этого компонента

const OrganizationListScreen = () => {
  const { countryId, cityId, categoryId } = useParams(); // Получение параметров из URL
  const navigate = useNavigate(); // Получение функции для навигации
  const location = useLocation(); // Получение текущего местоположения (URL)
  const [organizations, setOrganizations] = useState([]); // Состояние для хранения данных об организациях
  const [isLoading, setIsLoading] = useState(true); // Состояние для отображения индикатора загрузки

  // Получение данных из состояния маршрута для отображения выбранной страны, города и категории
  const countryName = location.state?.countryName || 'Страна не выбрана';
  const cityName = location.state?.cityName || 'Город не выбран';
  const categoryName = location.state?.categoryName || 'Категория не выбрана';

  // Обработчик нажатия кнопки "Назад"
  const handleBackClick = () => {
    navigate('/', { state: { selectedCountry: countryId, selectedCity: cityId, currentStep: 'category' } });
  };  

  useEffect(() => {
    setIsLoading(true); // Установка состояния загрузки в true
    getOrganizations(countryId, cityId, categoryId)
      .then(data => {
        console.log("Данные об организациях получены:", data); // Вывод данных в консоль (для отладки)
        setOrganizations(data); // Установка данных об организациях в состояние
        setIsLoading(false); // Установка состояния загрузки в false после получения данных
      })
      .catch(error => {
        console.error("Ошибка при получении данных об организациях:", error); // Вывод ошибки в консоль (для отладки)
        setIsLoading(false); // Установка состояния загрузки в false при ошибке
      });
  }, [countryId, cityId, categoryId]); // Эффект будет запускаться при изменении параметров в URL

  // Обработчик клика по организации, перенаправляющий на страницу организации
  const handleOrganizationClick = (organization) => {
    navigate(`/organization/${organization.companyId}`, { state: { organization } });
  };

  if (isLoading) {
    return <div className="loading">Загрузка...</div>; // Отображение индикатора загрузки, если данные еще загружаются
  }

  return (
    <div className="min-h-screen bg-[#ECF0F6]"> {/* Основной контейнер с фоном */}
      <div className="p-4"> {/* Контейнер с отступами */}
        <BackArrow className="w-18 h-6" onClick={handleBackClick} /> {/* Векторная иконка "Назад" */}
        <p className="text-m mt-2">{`${countryName}, ${cityName}`}</p> {/* Отображение выбранной страны и города */}
        <h1 className="text-3xl font-bold">{categoryName}</h1> {/* Отображение выбранной категории */}
      </div>
      <div>
        {organizations.length === 0 ? ( // Проверка наличия организаций
          <p className="not-found">Организации не найдены.</p> // Отображение сообщения, если организации отсутствуют
        ) : (
          <ul> {/* Список организаций */}
            {organizations.map(organization => ( // Маппинг данных об организациях для отображения списка
              <li key={organization.companyId} onClick={() => handleOrganizationClick(organization)} className="organization-item"> {/* Элемент списка организации */}
                <img src={organization.logo} alt={`Логотип ${organization.companyName}`} className="organization-logo" /> {/* Логотип организации */}
                <div className="organization-info"> {/* Информация организации */}
                  <h3>{organization.companyName}</h3> {/* Название организации */}
                  <p>{organization.address}</p> {/* Адрес организации */}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default OrganizationListScreen; // Экспорт компонента OrganizationListScreen
