import React, { useState, useEffect } from 'react'; // Импорт библиотек React, useState и useEffect
import { useNavigate, useLocation } from 'react-router-dom'; // Импорт хуков useNavigate и useLocation из библиотеки react-router-dom
import { getCountries, getCities, getCategories } from '../services/ApiService'; // Импорт функций для получения данных о странах, городах и категориях из ApiService
import TopBanner from '../assets/TopBanner.png'; // Импорт изображения верхнего баннера
import { ReactComponent as BackArrow } from '../assets/back-arrow.svg'; // Импорт компонента для стрелки "Назад"
import { CSSTransition, SwitchTransition } from 'react-transition-group'; // Импорт компонентов для анимаций из библиотеки react-transition-group
import './LocationAndCategorySelectScreen.css'; // Импорт стилей для данного компонента

import { ReactComponent as UZFlag } from '../assets/flags/UZ.svg'; // Импорт компонента для флага Узбекистана
import { ReactComponent as ARMFlag } from '../assets/flags/ARM.svg'; // Импорт компонента для флага Армении
import { ReactComponent as GRFlag } from '../assets/flags/GR.svg'; // Импорт компонента для флага Греции
import { ReactComponent as KIRFlag } from '../assets/flags/KIR.svg'; // Импорт компонента для флага Киргизии
import { ReactComponent as TADFlag } from '../assets/flags/TAD.svg'; // Импорт компонента для флага Таджикистана

import { ReactComponent as HotelIcon } from '../assets/icons/hotel.svg'; // Импорт компонента для иконки гостиницы
import { ReactComponent as RestaurantIcon } from '../assets/icons/restaurant.svg'; // Импорт компонента для иконки ресторана
import { ReactComponent as ShopIcon } from '../assets/icons/shop.svg'; // Импорт компонента для иконки магазина
import { ReactComponent as EntertainmentIcon } from '../assets/icons/entertainment.svg'; // Импорт компонента для иконки развлечения
import { ReactComponent as CellularIcon } from '../assets/icons/cellular.svg'; // Импорт компонента для иконки сотовой связи
// Продолжите импортировать остальные иконки

// Сопоставление русских названий категорий с иконками
const categoryIcons = {
  'Гостиницы': HotelIcon,
  'Рестораны': RestaurantIcon,
  'Магазины': ShopIcon,
  'Развлечения': EntertainmentIcon,
  'Сотовая связь': CellularIcon,
  // Добавьте оставшиеся сопоставления
};

// Объект с компонентами для флагов разных стран
const flagComponents = {
  UZ: UZFlag,
  ARM: ARMFlag,
  GR: GRFlag,
  KIR: KIRFlag,
  TAD: TADFlag,
};

// Компонент LocationAndCategorySelectScreen
const LocationAndCategorySelectScreen = () => {
    const navigate = useNavigate(); // Получение функции для навигации
    const location = useLocation(); // Получение текущего URL-адреса
    
    // Состояния компонента
    const [countries, setCountries] = useState([]); // Состояние для списка стран
    const [cities, setCities] = useState([]); // Состояние для списка городов
    const [categories, setCategories] = useState([]); // Состояние для списка категорий
    const [selectedCountry, setSelectedCountry] = useState(null); // Выбранная страна
    const [selectedCity, setSelectedCity] = useState(null); // Выбранный город
    const [currentStep, setCurrentStep] = useState('country'); // Текущий шаг
    const [isTransitioning, setIsTransitioning] = useState(false); // Состояние для анимации перехода между экранами
  
    // Загрузка стран
    useEffect(() => {
      getCountries().then(data => {
        setCountries(data);
        if (location.state?.selectedCountry) {
          const foundCountry = data.find(country => country.id === location.state.selectedCountry);
          if (foundCountry) {
            setSelectedCountry(foundCountry);
            setCurrentStep('city');
          }
        }
      });
    }, [location.state?.selectedCountry]);
  
    // Загрузка городов
    useEffect(() => {
      if (selectedCountry) {
        getCities(selectedCountry.id).then(data => {
          setCities(data);
          if (location.state?.selectedCity) {
            const foundCity = data.find(city => city.id === location.state.selectedCity);
            if (foundCity) {
              setSelectedCity(foundCity);
              setCurrentStep('category');
              // Загрузка категорий после выбора города
              getCategories(selectedCountry.id, foundCity.id).then(setCategories);
            }
          }
        });
      }
    }, [selectedCountry, location.state?.selectedCity]);
    
    useEffect(() => {
        if (selectedCity && selectedCountry) {
          getCategories(selectedCountry.id, selectedCity.id).then(data => {
            setCategories(data);
            setCurrentStep('category');
          });
        }
      }, [selectedCity, selectedCountry]); // Добавлен selectedCountry в зависимости
      
    
  
    // Обработчики выбора страны, города и категории
    const handleCountrySelect = (country) => {
      setSelectedCountry(country);
      setCurrentStep('city');
    };
  
    const handleCitySelect = (city) => {
      setSelectedCity(city);
      setCurrentStep('category');
    };
  
    const handleCategorySelect = (category) => {
      setIsTransitioning(true);
    
      // Добавьте задержку, соответствующую времени анимации
      setTimeout(() => {
        navigate(`/organizations/${selectedCountry.id}/${selectedCity.id}/${category.id}`, {
          state: {
            countryName: selectedCountry.name,
            cityName: selectedCity.name,
            categoryName: category.name
          }
        });
      }, 300); // 300 мс - время анимации
    };
  
    return (
      <div className={`flex flex-col min-h-screen bg-[#ECF0F6] ${isTransitioning ? 'animate-out' : ''}`}>
        <SwitchTransition>
          <CSSTransition
            key={currentStep}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <div>
              {currentStep === 'country' && (
                <>
                  <img src={TopBanner} alt="Верхний баннер" className="w-full" />
                  <div className="mt-8 px-4 flex items-center">
                    <div className="w-8 h-8 bg-pink-500 rounded-full flex items-center justify-center text-white font-bold mr-4">
                      1
                    </div>
                    <h1 className="text-3xl font-bold">Выберите страну</h1>
                  </div>
                  <ul className="space-y-4 mt-6 mx-4">
                    {countries.map((country) => {
                      const FlagIcon = flagComponents[country.id];
                      return (
                        <li key={country.id} onClick={() => handleCountrySelect(country)} className="flex items-center p-4 bg-white rounded-full shadow-lg cursor-pointer hover:bg-gray-50">
                          <FlagIcon className="w-16 h-10 mr-4" />
                          <span className="flex-1 font-medium text-lg">{country.name}</span>
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
    
              {currentStep === 'city' && selectedCountry && (
                <>
                  {/* Кнопка "Назад" */}
                  <div className="px-4 mt-4">
                    <button onClick={() => setCurrentStep('country')} className="back-button">
                      <BackArrow />
                    </button>
                  </div>
    
                  {/* Общий контейнер для индикаторов и пунктирной линии */}
                  <div className="flex flex-col items-start px-4 mt-4">
                    {/* Индикатор шага "1" с флагом и названием страны */}
                    <div className="flex items-center mb-2 w-full">
                      <div className="step-indicator w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center text-black font-bold">
                        1
                      </div>
                      <div className="country-info flex-1 flex items-center ml-2" onClick={() => setCurrentStep('country')} style={{ backgroundColor: '#ECF0F6', borderColor: '#D1D5DB', borderWidth: 1, padding: '12px 20px', borderRadius: '9999px' }}>
                        {selectedCountry && React.createElement(flagComponents[selectedCountry.id], { className: "flag-icon w-12 h-8 mr-4" })}
                        <span className="font-medium text-lg">{selectedCountry.name}</span>
                      </div>
                    </div>
    
                    {/* Вертикальная пунктирная линия */}
                    <div className="dotted-line h-6 w-0 border-l-2 border-dashed border-gray-400 mx-4"></div>
    
                    {/* Индикатор шага "2" и заголовок "Выберите город" */}
                    <div className="flex items-center w-full mt-2">
                      <div className="step-indicator w-8 h-8 bg-pink-500 rounded-full flex items-center justify-center text-white font-bold">
                        2
                      </div>
                      <h1 className="text-3xl font-bold ml-2">Выберите город</h1>
                    </div>
                  </div>
    
                  {/* Список городов */}
                  <div className="flex flex-wrap gap-4 mx-4 mt-6">
                    {cities.sort((a, b) => a.name.localeCompare(b.name)).map((city) => (
                      <div key={city.id} onClick={() => handleCitySelect(city)} className="flex items-center px-4 py-4 bg-white rounded-full shadow-lg cursor-pointer hover:bg-gray-50">
                        <span className="flex-1 font-medium text-lg">{city.name}</span>
                      </div>
                    ))}
                  </div>
                </>
              )}
    
              {currentStep === 'category' && selectedCity && (
                <>
                  {/* Кнопка "Назад" */}
                  <div className="px-4 mt-4">
                    <button onClick={() => setCurrentStep('city')} className="back-button">
                      <BackArrow />
                    </button>
                  </div>
    
                  {/* Индикаторы шагов и пунктирная линия */}
                  <div className="flex flex-col items-start px-4 mt-4">
                    {/* Индикатор страны */}
                    <div className="flex items-center mb-2 w-full">
                      <div className="step-indicator w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center text-black font-bold">
                        1
                      </div>
                      <button onClick={() => setCurrentStep('country')} className="country-info flex-1 flex items-center ml-2" style={{ backgroundColor: '#ECF0F6', borderColor: '#D1D5DB', borderWidth: 1, padding: '12px 20px', borderRadius: '9999px' }}>
                        {selectedCountry && React.createElement(flagComponents[selectedCountry.id], { className: "flag-icon w-12 h-8 mr-4" })}
                        <span className="font-medium text-lg">{selectedCountry.name}</span>
                      </button>
                    </div>
    
                    {/* Пунктирная линия */}
                    <div className="dotted-line h-6 w-0 border-l-2 border-dashed border-gray-400 mx-4"></div>
    
                    {/* Индикатор города */}
                    <div className="flex items-center mb-2 w-full">
                      <div className="step-indicator w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center text-black font-bold">
                        2
                      </div>
                      <button onClick={() => setCurrentStep('city')} className="city-info flex-1 flex items-center ml-2" style={{ backgroundColor: '#ECF0F6', borderColor: '#D1D5DB', borderWidth: 1, padding: '12px 20px', borderRadius: '9999px' }}>
                        <span className="font-medium text-lg">{selectedCity.name}</span>
                      </button>
                    </div>
    
                    {/* Пунктирная линия */}
                    <div className="dotted-line h-6 w-0 border-l-2 border-dashed border-gray-400 mx-4"></div>
    
                    {/* Индикатор категории */}
                    <div className="flex items-center w-full mt-2">
                      <div className="step-indicator w-8 h-8 bg-pink-500 rounded-full flex items-center justify-center text-white font-bold">
                        3
                      </div>
                      <h1 className="text-3xl font-bold ml-2">Выберите категорию</h1>
                    </div>
                  </div>
    
                  {/* Список категорий */}
                  <div className="flex flex-wrap gap-2 mx-4 mt-6">
                    {categories.map((category) => {
                      const IconComponent = categoryIcons[category.name];
                      return (
                        <div key={category.id} onClick={() => handleCategorySelect(category)} className="flex items-center px-6 py-2 bg-white rounded-full shadow-lg cursor-pointer hover:bg-gray-50">
                          {IconComponent ? <IconComponent className="w-6 h-6 mr-2" /> : null}
                          <span className="font-medium text-lg">{category.name} <span className="text-pink-500">({category.count})</span></span>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    );
    
};

export default LocationAndCategorySelectScreen;
