import React from 'react';
import './SimpleModal.css'; // Импорт стилей для модального окна

const SimpleModal = ({ isOpen, onClose, images, selectedImageIndex }) => {
  // Проверяем, открыто ли модальное окно. Если нет, то компонент ничего не рендерит.
  if (!isOpen) return null;

  // Получаем URL изображения, которое нужно отобразить в модальном окне.
  const selectedImageUrl = images[selectedImageIndex];

  return (
    // Основной контейнер модального окна. При клике на фон вызывается функция onClose.
    <div className="modal-overlay" onClick={onClose}>
      {/* Контейнер для содержимого модального окна. Остановка всплытия события клика, 
      чтобы клик по самому модальному окну не закрывал его. */}
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {/* Отображение выбранного изображения. */}
        <img src={selectedImageUrl} alt={`Фото ${selectedImageIndex}`} className="modal-image" />
      </div>
    </div>
  );
};

export default SimpleModal;
